@mixin usa-paragraph-style {
  p {
    @extend %usa-paragraph;
  }
}

@mixin usa-link-style {
  a {
    @include typeset-link;
  }
}

@mixin usa-headings-styles {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %usa-heading;
  }

  h1 {
    @include h1;
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  h6 {
    @include h6;
  }
}

@mixin usa-content-styles {
  @include usa-paragraph-style;
  @include usa-link-style;
  @include usa-headings-styles;
  @include usa-list-styles;
  @include usa-table-styles;
}
