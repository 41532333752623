.usa-link {
  @include typeset-link;
}

// External link consider 'effortless style approach':
// [href^='http:']:not([href*='my-domain.com'])
// [href^='https:']:not([href*='my-domain.com'])

.usa-link--external {
  @include external-link(
    usa-icons-bg/launch--blue-60v,
    usa-icons-bg/launch--blue-70v
  );

  &.usa-link--alt {
    @include external-link(
      usa-icons-bg/launch--gray-5,
      usa-icons-bg/launch--white
    );
  }
}
