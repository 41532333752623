// Outputs text-decoration and color properties

@mixin u-text-decoration($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  @each $this-value in $value {
    @if map-has-key($all-color-shortcodes, smart-quote($this-value)) {
      text-decoration-color: color(smart-quote($this-value)) #{$important};
    } @else {
      text-decoration: get-uswds-value(text-decoration, $value...)
        #{$important};
    }
  }
}
