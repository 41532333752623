// Outputs order

@mixin u-order($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  order: get-uswds-value(order, $value...) #{$important};
}
