@mixin external-link(
  $external-link,
  $external-link-hover,
  $image-path: $theme-image-path
) {
  &::after {
    $icon-size: 0.65em;
    background-image: url("#{$image-path}/#{$external-link}.svg");
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline;
    margin-left: units(2px);
    padding-left: $icon-size;
  }

  &:hover::after {
    @include add-background-svg("#{$external-link-hover}", $image-path);
  }
}
