// Apply :focus behavior to focusable elements only (For IE 11)
input,
select,
textarea,
button {
  &:not([disabled]) {
    &:focus {
      @include focus-outline;
    }
  }
}

iframe,
[href],
[tabindex],
[contentEditable="true"] {
  &:focus {
    @include focus-outline;
  }
}

.usa-focus {
  @include focus-outline;
}
