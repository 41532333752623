@mixin display-icon($icon, $direction, $size, $margin, $hover) {
  &::#{$direction} {
    @include add-background-svg("#{$icon}");
    background-size: 100%;
    content: "";
    display: inline-block;
    height: $size;
    width: $size;

    /* stylelint-disable block-closing-brace-newline-after, at-rule-empty-line-before */
    @if $direction == "after" {
      margin-left: $margin;
    } @else {
      margin-right: $margin;
    }
    /* stylelint-enable */
  }

  @if $hover == "hover" {
    &:hover::#{$direction} {
      @include add-background-svg("#{$icon}-hover");
    }
  }
}

@mixin remove-icon($direction) {
  &::#{$direction} {
    display: none;
  }
}

@mixin add-icon(
  $icon-name,
  $direction,
  $image-size,
  $container-size,
  $margin,
  $hover
) {
  &::#{$direction} {
    @include add-background-svg("#{$icon-name}");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: units($image-size);
    content: "";
    display: inline-block;
    height: units($container-size);
    width: units($container-size);

    /* stylelint-disable block-closing-brace-newline-after, at-rule-empty-line-before */
    @if $direction == "after" {
      margin-left: units($margin);
    } @else {
      margin-right: units($margin);
    }
    /* stylelint-enable */
  }

  @if $hover == "hover" {
    &:hover::#{$direction} {
      @include add-background-svg("#{$icon-name}-hover");
    }
  }
}

// New icon mixins using SVG mask technique
//
// $image may be a specific SVG or a list in the form
// ($base, $variant, $variant-alt, $bg)
// the mixin will pick IE11-compatible svgs named
// [base]-[variant].svg based on the specified background-color

@mixin add-color-icon($icon-object, $contrast-bg) {
  $filename-base: map-get($icon-object, "name");
  $svg-height: map-get($icon-object, "svg-height");
  $svg-width: map-get($icon-object, "svg-width");
  $aspect: $svg-width / $svg-height;
  $height: if(
    unitless(map-get($icon-object, "height")),
    units(map-get($icon-object, "height")),
    map-get($icon-object, "height")
  );
  $width: $height * $aspect;
  $container-height: if(
    map-has-key($icon-object, "container-height"),
    units(map-get($icon-object, "container-height")),
    null
  );
  $container-width: if(
    map-has-key($icon-object, "container-width"),
    units(map-get($icon-object, "container-width")),
    null
  );
  $color: if(
    map-has-key($icon-object, "color"),
    map-get($icon-object, "color"),
    "ink"
  );
  $color-variant: if(
    map-has-key($icon-object, "color-variant"),
    map-get($icon-object, "color-variant"),
    "white"
  );
  $color-hover: if(
    map-has-key($icon-object, "color-hover"),
    map-get($icon-object, "color-hover"),
    null
  );
  $rotate: if(
    map-has-key($icon-object, "rotate"),
    map-get($icon-object, "rotate"),
    null
  );
  $path: if(
    map-has-key($icon-object, "path"),
    map-get($icon-object, "path"),
    $theme-image-path
  );
  $ie11-variant: get-color-token-from-bg($contrast-bg, "white", "black");
  $filename-ie11: if(
    $ie11-variant == "white",
    "usa-icons-bg/#{$filename-base}--white.svg",
    "usa-icons/#{$filename-base}.svg"
  );

  $mask-props: url("#{$path}/usa-icons/#{$filename-base}.svg") no-repeat center /
    #{$width} #{$height};
  $image-props: url("#{$path}/#{$filename-ie11}") no-repeat center / #{$width} #{$height};

  // Default background shorthand for browsers that don't support mask or supports.
  background: $image-props;
  display: inline-block;
  height: if($container-height, $container-height, $height);
  width: if($container-width, $container-width, $width);
  @if $rotate {
    transform: rotate($rotate);
  }

  // Mask supported styles
  @supports (mask: url("")) {
    background: none;
    background-color: color($color);
    mask: $mask-props;
    @if $color-hover {
      &:hover {
        background-color: color($color-hover);
      }
    }
  }
}

// Places an icon before or after an element as an inline-block,
// using the `:before` or `:after` pseudoelements.
@mixin place-icon(
  $icon-object,
  $direction,
  $margin,
  $vertical-align,
  $contrast-bg
) {
  $color-hover: if(
    map-has-key($icon-object, "color-hover"),
    map-get($icon-object, "color-hover"),
    null
  );
  &::#{$direction} {
    @include add-color-icon($icon-object, $contrast-bg);
    content: "";
    vertical-align: $vertical-align;

    @if $direction == "after" {
      margin-left: units($margin);
    } @else {
      margin-right: units($margin);
    }
  }

  @if $color-hover {
    &:hover::#{$direction} {
      content: ""; // Added to address a weird display bug
      background-color: color($color-hover);
    }
  }
}
