// Outputs flex

$flex-utililies: (
  align-items:
    map-collect(
      map-deep-get($system-properties, align-items, standard),
      map-deep-get($system-properties, align-items, extended)
    ),
  align-self:
    map-collect(
      map-deep-get($system-properties, align-self, standard),
      map-deep-get($system-properties, align-self, extended)
    ),
  flex:
    map-collect(
      map-deep-get($system-properties, flex, standard),
      map-deep-get($system-properties, flex, extended)
    ),
  flex-direction:
    map-collect(
      map-deep-get($system-properties, flex-direction, standard),
      map-deep-get($system-properties, flex-direction, extended)
    ),
  flex-wrap:
    map-collect(
      map-deep-get($system-properties, flex-wrap, standard),
      map-deep-get($system-properties, flex-wrap, extended)
    ),
  justify-content:
    map-collect(
      map-deep-get($system-properties, justify-content, standard),
      map-deep-get($system-properties, justify-content, extended)
    ),
);

@mixin u-flex($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  @each $this-value in $value {
    $match: false;
    @each $property, $map in $flex-utililies {
      @if not $match and map-has-key($map, $this-value) {
        #{$property}: get-uswds-value($property, $this-value...) #{$important};
        $match: true;
      }
    }
    @if not $match {
      @error '`#{$this-value}` is not a valid `flex` value.';
    }
  }
}
