// Outputs align-items

@mixin u-align-items($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  align-items: get-uswds-value(align-items, $value) #{$important};
}
