// Flexbox positioning to move sidenav below main content on small screens
.usa-layout-docs__sidenav {
  order: 2;
  padding-top: units(4);

  @include at-media("desktop") {
    padding-top: 0;
  }
}

.usa-layout-docs__main {
  @include at-media("desktop") {
    order: 2;
  }
}
