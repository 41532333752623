// Outputs measure max-width

@mixin u-measure($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  max-width: get-uswds-value(measure, $value...) #{$important};
}
