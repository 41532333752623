// Outputs overflow

@mixin u-overflow($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  overflow: get-uswds-value(overflow, $value...) #{$important};
}

@mixin u-overflow-x($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  overflow-x: get-uswds-value(overflow, $value...) #{$important};
}

@mixin u-overflow-y($value...) {
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  overflow-y: get-uswds-value(overflow, $value...) #{$important};
}
