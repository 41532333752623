// Unstyled list helper
@mixin unstyled-list() {
  @include u-margin-y(0);
  list-style-type: none;
  padding-left: 0;

  > li {
    margin-bottom: 0;
    max-width: unset;
  }
}
