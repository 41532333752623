/*
----------------------------------------
family()
----------------------------------------
Get a font-family stack
----------------------------------------
*/

@mixin u-font-family($family) {
  font-family: ff($family);
}

/*
----------------------------------------
size()
----------------------------------------
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/

@mixin u-font-size($family, $scale) {
  font-size: font-size($family, $scale);
}

/*
----------------------------------------
font()
----------------------------------------
Get a font-family stack
AND
Get a normalized font-size in rem from
a family and a type size in either
system scale or project scale
----------------------------------------
*/

@mixin u-font($family, $scale) {
  font-family: ff($family);
  font-size: font-size($family, $scale);
}
