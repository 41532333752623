.usa-prose {
  @include typeset($theme-prose-font-family);

  & > {
    @include usa-paragraph-style;
    @include usa-headings-styles;
    @include usa-list-styles;
    @include usa-table-styles;
  }
}
